(function() {
  function vendorModule() {
    'use strict';

    return {
      'default': self.printJS,
      __esModule: true,
    };
  }

  define('print-js', [], vendorModule);
})();
